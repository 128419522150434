/**
 * External dependencies.
 */
import React, { createContext, useContext, useState } from 'react';
import { nanoid } from 'nanoid';
import cx from 'classnames';

/**
 * Internal dependencies.
 */
import { AccordionContext } from '@components/accordion/accordion';

export const AccordionSectionContext = createContext({
	id: null,
	isCurrentSectionActive: false,
});

const AccordionSection = ({ children }) => {
	const [id] = useState(nanoid);
	const { activeSection } = useContext(AccordionContext);
	const isCurrentSectionActive = activeSection === id;

	return (
		<AccordionSectionContext.Provider
			value={{
				id,
				isCurrentSectionActive,
			}}
		>
			<div
				className={cx('accordion__section', {
					'is-current': isCurrentSectionActive,
				})}
			>
				{children}
			</div>
		</AccordionSectionContext.Provider>
	);
};

export default AccordionSection;
