/**
 * External dependencies.
 */
import React from 'react';

const AccordionContainer = ({ children }) => (
	<div className="accordion__container">
		{children}
	</div>
);

export default AccordionContainer;
