/**
 * External dependencies.
 */
import React, { useContext, useEffect, useState, useCallback } from 'react';
import AnimateHeight from 'react-animate-height';

/**
 * Internal dependencies.
 */
import { AccordionSectionContext } from '@components/accordion/accordion-section';

const AccordionBody = ({ isAlwaysOpen = false, children }) => {
	const { isCurrentSectionActive } = useContext(AccordionSectionContext);
	const [height, setHeight] = useState(22);
	const [winSize, setWinSize] = useState(0);

	const handleHeight = useCallback(() => {
		const size = winSize > 0 ? winSize : window.innerWidth;
		if (size <= 767) {
			setHeight(isCurrentSectionActive ? 'auto' : 0);
		} else {
			setHeight(isCurrentSectionActive ? 'auto' : 22);
		}
	}, [winSize, setHeight, isCurrentSectionActive]);

	const handleResize = useCallback(() => {
		setWinSize(window.innerWidth);
		handleHeight();
	}, [setWinSize, handleHeight]);

	useEffect(() => {
		handleHeight();
	}, [isCurrentSectionActive, handleHeight]);

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [handleResize]);

	if (isAlwaysOpen) {
		return (
			<div
				className="accordion__body"
				style={{ height: 'auto' }}
			>
				{children}
			</div>
		);
	}

	return (
		<AnimateHeight
			className="accordion__body"
			duration={300}
			height={height}
		>
			{children}
		</AnimateHeight>
	);
};

export default AccordionBody;
