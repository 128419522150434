/**
 * External dependencies.
 */
import { nanoid } from 'nanoid';
import { is } from 'ramda';

export default function addKeyProperty(data) {
	return data.map((item) => {
		if (is(String, item)) {
			return {
				text: item,
				_key: nanoid(),
			};
		}

		return {
			...item,
			_key: nanoid(),
		};
	});
}
