/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

/**
 * Internal dependencies.
 */
import cfs from '@utils/classes-from-string';

const Grid = ({
	className,
	modifier,
	alignItems,
	children,
	column,
	expanded,
	justify,
	noFlex,
	lg,
	lgMid,
	md,
	mdMid,
	row,
	sm,
	size,
	isAside,
	isContent,
}) => {
	const isRow = row || !column;
	const gridModifiers = cfs('row', modifier);

	const classes = cx(
		{
			'cols': isRow,
			'col': !isRow,
			'expanded': isRow && expanded,
			'no-flex': noFlex,
			'is-aside': !isRow && isAside,
			'is-content': !isRow && isContent,
		},
		isRow && gridModifiers,
		className,
		(isRow && justify) && justify,
		(isRow && alignItems) && `align-${alignItems}`,
		(!isRow && size) && `is-col-${size}`,
		(!isRow && sm) && `is-col-sm-${sm}`,
		(!isRow && md) && `is-col-md-${md}`,
		(!isRow && mdMid) && `is-col-md-mid-${mdMid}`,
		(!isRow && lg) && `is-col-lg-${lg}`,
		(!isRow && lgMid) && `is-col-lg-mid-${lgMid}`,
	);

	return (
		<div className={classes}>{children}</div>
	);
};

Grid.propTypes = {
	className: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
		PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object]))],
	),
	modifier: PropTypes.string,
	alignItems: PropTypes.string,
	children: PropTypes.node,
	column: PropTypes.bool,
	expanded: PropTypes.bool,
	justify: PropTypes.string,
	noFlex: PropTypes.bool,
	lg: PropTypes.string,
	lgMid: PropTypes.string,
	md: PropTypes.string,
	mdMid: PropTypes.string,
	row: PropTypes.bool,
	sm: PropTypes.string,
	size: PropTypes.string,
	isAside: PropTypes.bool,
	isContent: PropTypes.bool,
};

export default Grid;
