/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import ListSimple from '@components/list-simple/list-simple';
import SectionGroup from '@components/section-group/section-group';

const PageCaseStudies = ({ section }) => {
	const caseStudies = section.caseStudyPosts.map((caseStudy) => ({
		id: caseStudy.id,
		name: caseStudy.title,
		url: caseStudy.uri,
	}));

	return (
		<SectionGroup
			staggerOffset={section.staggerOffset}
			title={section.sectionTitle}
			className="case-studies"
		>
			<ListSimple
				isWithBorderBottom
				isThreeColumns
				asLinks
				data={caseStudies}
			/>
		</SectionGroup>
	);
};

export default PageCaseStudies;
