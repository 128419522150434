/**
 * External dependencies.
 */
import React from 'react';

const AccordionOutro = ({ children }) => (
	<div className="accordion__outro">
		{children}
	</div>
);

AccordionOutro.Head = ({ title, children }) => (
	<div className="accordion__head">
		<h4 className="accordion__title">{title}</h4>

		<p>{children}</p>
	</div>
);

AccordionOutro.Body = ({ children }) => (
	<div className="accordion__body">
		{children}
	</div>
);

export default AccordionOutro;
