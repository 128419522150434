/**
 * External dependencies.
 */
import { useCallback, useMemo } from 'react';

/**
 * Internal dependencies.
 */
import PageFourColumns from '@page/blocks/page-four-columns/page-four-columns';
import PageAccordion from '@page/blocks/page-accordion/page-accordion';
import PageCaseStudies from '@page/blocks/page-case-studies/page-case-studies';
import PageCaseStudiesLogos from '@page/blocks/page-case-studies-logos/page-case-studies-logos';
import PageJobs from '@page/blocks/page-jobs/page-jobs';
import PageTeam from '@page/blocks/page-team/page-team';
import PageColumns from '@page/blocks/page-columns/page-columns';
import PageImages from '@page/blocks/page-images/page-images';
import PageTestimonial from '@page/blocks/page-testimonial/page-testimonial';
import PageGallery from '@page/blocks/page-gallery/page-gallery';

export default function usePageSections() {
	const supportedTypes = useMemo(() => ['page', 'job', 'caseStudy'], []);

	const generateSections = (type) => ({
		[`${type}_Sectionsoptions_Sections_FourColumns`]: PageFourColumns,
		[`${type}_Sectionsoptions_Sections_Accordion`]: PageAccordion,
		[`${type}_Sectionsoptions_Sections_CaseStudies`]: PageCaseStudies,
		[`${type}_Sectionsoptions_Sections_CaseStudiesLogos`]: PageCaseStudiesLogos,
		[`${type}_Sectionsoptions_Sections_Jobs`]: PageJobs,
		[`${type}_Sectionsoptions_Sections_Team`]: PageTeam,
		[`${type}_Sectionsoptions_Sections_Columns`]: PageColumns,
		[`${type}_Sectionsoptions_Sections_Images`]: PageImages,
		[`${type}_Sectionsoptions_Sections_Testimonial`]: PageTestimonial,
		[`${type}_Sectionsoptions_Sections_Gallery`]: PageGallery,
	});

	const buildSections = useCallback(() => {
		let sections = {};

		supportedTypes.forEach((type) => {
			sections = {
				...sections,
				...generateSections(type),
			};
		});

		return sections;
	}, [supportedTypes]);

	return useMemo(() => buildSections(), [buildSections]);
}

