/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import cx from 'classnames';

/**
 * Internal dependencies.
 */
import addKeyProperty from '@utils/add-key-property';

const ListSimple = ({
	asLinks,
	isWithBorderBottom,
	isThreeColumns,
	data,
}) => (
	<ul
		className={cx('list-simple', {
			'is-links': asLinks,
			'is-with-border-bottom': isWithBorderBottom,
			'is-three-columns': isThreeColumns,
		})}
	>
		{addKeyProperty(data).map((listItem) => (
			<li key={listItem._key}>
				{
					asLinks
						? (
							<Link to={listItem.url}>
								{listItem.name}
							</Link>
						) : listItem.text
				}
			</li>
		))}
	</ul>
);

ListSimple.propTypes = {
	data: PropTypes.array.isRequired,
	asLinks: PropTypes.bool,
	isWithBorderBottom: PropTypes.bool,
	isThreeColumns: PropTypes.bool,
};

ListSimple.defaultProps = {
	data: [],
	asLinks: false,
	isWithBorderBottom: false,
	isThreeColumns: false,
};

export default ListSimple;
