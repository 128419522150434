/**
 * External dependencies.
 */
import React from 'react';

const AccordionHeadStatic = ({ children }) => (
	<div className="accordion__head">
		{children}
	</div>
);

export default AccordionHeadStatic;
