/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';

const ListLogos = ({ data }) => (
	<ul className="list-logos">
		{data.map((logo) => (
			<li key={logo.url}>
				<img
					src={logo.url}
					alt=""
				/>
			</li>
		))}
	</ul>
);

ListLogos.propTypes = {
	data: PropTypes.array.isRequired,
};

ListLogos.defaultProps = {
	data: [],
};

export default ListLogos;
