/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import cx from 'classnames';

/**
 * Internal dependencies.
 */
import Grid from '@components/gird/grid';
import { slideFadeInAnimation } from '@utils/framer-animations';

const SectionGroup = ({
	title,
	isFull,
	staggerOffset,
	noSpacing,
	className,
	children,
}) => (
	<AnimatePresence>
		<motion.section
			className={cx('section-group', { 'no-spacing': noSpacing }, className)}
			{...slideFadeInAnimation(staggerOffset)}
		>
			{
				isFull
					? children : (
						<Grid row>
							<Grid
								column
								isAside
							>
								{title && (
									<motion.h6>{title}</motion.h6>
								)}
							</Grid>

							<Grid
								column
								isContent
							>
								{children}
							</Grid>
						</Grid>
					)
			}
		</motion.section>
	</AnimatePresence>
);

SectionGroup.propTypes = {
	title: PropTypes.string,
	isFull: PropTypes.bool,
	noSpacing: PropTypes.bool,
	staggerOffset: PropTypes.number,
	children: PropTypes.node,
};

SectionGroup.defaultProps = {
	isFull: false,
	noSpacing: false,
	staggerOffset: 0,
};

export default SectionGroup;
