/**
 * External dependencies.
 */
import React, { createContext, useState } from 'react';

/**
 * Internal dependencies.
 */
import AccordionSection from '@components/accordion/accordion-section';
import AccordionHead from '@components/accordion/accordion-head';
import AccordionBody from '@components/accordion/accordion-body';
import AccordionOutro from '@components/accordion/accordion-outro';
import AccordionContainer from '@components/accordion/accordion-container';
import AccordionHeadStatic from '@components/accordion/accordion-head-static';

export const AccordionContext = createContext({
	activeSection: null,
	setActiveSection: () => {},
});

const Accordion = ({ children }) => {
	const [activeSection, setSection] = useState(null);

	const setActiveSection = (sectionId) => {
		setSection(activeSection === sectionId ? null : sectionId);
	};

	return (
		<AccordionContext.Provider
			value={{
				activeSection,
				setActiveSection,
			}}
		>
			<div className="accordion">
				{children}
			</div>
		</AccordionContext.Provider>
	);
};

Accordion.Section = AccordionSection;
Accordion.Head = AccordionHead;
Accordion.HeadStatic = AccordionHeadStatic;
Accordion.Body = AccordionBody;
Accordion.Outro = AccordionOutro;
Accordion.Container = AccordionContainer;

export default Accordion;
