/**
 * External dependencies.
 */
import React from 'react';

const Member = ({ member }) => (
	<div className="member">
		<figure className="member__image">
			<img
				src={member.image}
				alt={member.name}
			/>
		</figure>

		{member.name && (
			<h5 className="member__title">{member.name}</h5>
		)}

		{member.position && (
			<h5 className="member__title is-faded">{member.position}</h5>
		)}

		{member.content && (
			<div
				className="member__entry"
				dangerouslySetInnerHTML={{ __html: member.content }}
			/>
		)}
	</div>
);

export default Member;
