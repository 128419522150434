/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import addKeyProperty from '@utils/add-key-property';
import Accordion from '@components/accordion/accordion';
import SectionGroup from '@components/section-group/section-group';

const PageAccordion = ({ section }) => (
	<SectionGroup
		staggerOffset={section.staggerOffset}
		title={section.sectionTitle}
	>
		<Accordion>
			<Accordion.Container>
				{addKeyProperty(section.accordion).map((accordionItem) => (
					<Accordion.Section key={accordionItem._key}>
						<Accordion.HeadStatic>{accordionItem.header}</Accordion.HeadStatic>

						<Accordion.Body isAlwaysOpen>{accordionItem.description}</Accordion.Body>
					</Accordion.Section>
				))}
			</Accordion.Container>

			<Accordion.Outro>
				<Accordion.Outro.Head title="The result">{section.result.header}</Accordion.Outro.Head>

				<Accordion.Outro.Body>{section.result.description}</Accordion.Outro.Body>
			</Accordion.Outro>
		</Accordion>
	</SectionGroup>
);

PageAccordion.propTypes = {
	section: PropTypes.object.isRequired,
};

export default PageAccordion;
