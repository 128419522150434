/**
 * External dependencies.
 */
import React, { Fragment } from 'react';

/**
 * Internal dependencies.
 */
import usePageHead from '@hooks/use-page-head';

import PageContact from '@page/blocks/page-contact/page-contact';
import PageHeading from '@page/blocks/page-heading/page-heading';
import PageSections from '@page/blocks/page-sections/page-sections';

const PageBase = ({ data: { page, contact }, pageContext, children, location }) => {
	const { isHomePage } = usePageHead(page, pageContext);
	const sectionsLength = page?.sectionsOptions?.sections ? page.sectionsOptions.sections.length : 0;

	const getStaggerOffset = (total) => {
		return total * 100;
	};

	return (
		<Fragment>
			<PageHeading
				title={page?.title}
				content={page?.content}
				textSizeSmall={!isHomePage}
			/>

			<PageSections
				nextPost={page?.nextPost}
				sections={page.sectionsOptions.sections}
			/>

			{children({ staggerOffset: getStaggerOffset(sectionsLength) })}

			<PageContact
				staggerOffset={getStaggerOffset(sectionsLength + 1)}
				contact={contact}
				location={location}
			/>
		</Fragment>
	);
};

PageBase.defaultProps = {
	data: {
		page: {
			title: '',
			metaTitle: {
				title: '',
			},
		},
	},
	pageContext: {
		metaTitlePrefix: '',
	},
	children: () => {},
};

export default PageBase;
