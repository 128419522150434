/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import SectionGroup from '@components/section-group/section-group';
import ListJobs from '@components/list-jobs/list-jobs';

const PageJobs = ({ section }) => (
	<SectionGroup
		staggerOffset={section.staggerOffset}
		title={section.sectionTitle}
	>
		<ListJobs data={section.jobPosts} />
	</SectionGroup>
);

export default PageJobs;
