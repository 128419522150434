/**
 * External dependencies.
 */
import { is, isNil, isEmpty } from 'ramda';

/**
 * Generates an array of strings which
 * represents modifiers of the specified element.
 *
 * @param element
 * @param payload
 * @return {*|string[]}
 */
export default function cfs(element, payload) {
	if (isNil(element)) {
		throw new Error('First argument is missing in generateClassesFromString');
	}

	if (!is(String, element)) {
		throw new Error(`element argument must be type of String in generateClassesFromString`);
	}

	if (isEmpty(element)) {
		return payload && payload.split(' ').map(item => item);
	}

	return payload && payload.split(' ').map(item => `${element}--${item}`);
}
