/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Heading = ({
	titleTag: HTMLElement,
	title,
	entry,
	textSizeSmall,
}) => (
	<div className="heading">
		{title && (
			<HTMLElement>{title}</HTMLElement>
		)}

		{entry && <div
			dangerouslySetInnerHTML={{ __html: entry }}
			className={cx('heading__entry', { 'is-text-small': textSizeSmall })}
		/>}

	</div>
);

Heading.propTypes = {
	titleTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
	title: PropTypes.string,
	entry: PropTypes.string,
	textSizeSmall: PropTypes.bool,
};

Heading.defaultProps = {
	titleTag: 'h1',
};

export default Heading;
