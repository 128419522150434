/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';

const Contact = ({ title, email, phone }) => (
	<div className="contact">
		<span className="contact__title">{title}</span>

		{email && (
			<a href={`mailto:${email}`}>{email}</a>
		)}

		{phone && (
			<a href={`tel:${phone}`}>{phone}</a>
		)}
	</div>
);

Contact.propTypes = {
	title: PropTypes.string.isRequired,
	email: PropTypes.string,
	phone: PropTypes.string,
};

export default Contact;
