/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import SectionGroup from '@components/section-group/section-group';
import Testimonial from '@components/testimonial/testimonial';

const PageTestimonial = ({ section }) => (
	<SectionGroup
		isFull
		staggerOffset={section.staggerOffset}
	>
		<Testimonial
			title={section.testimonial.testimonialOptions.testimonialauthor}
			content={section.testimonial.content}
			position={section.testimonial.testimonialOptions.testimonialposition}
		/>
	</SectionGroup>
);

export default PageTestimonial;
