/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import Contact from '@components/contacts/contact';

const Contacts = ({ children }) => (
	<div className="contacts">
		{children}
	</div>
);

Contacts.Item = Contact;

export default Contacts;
