/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import ListLogos from '@components/list-logos/list-logos';
import SectionGroup from '@components/section-group/section-group';

const PageGallery = ({ section }) => {
	const images = section.images.map(({ mediaItemUrl }) => ({ url: mediaItemUrl }));

	return (
		<SectionGroup
			staggerOffset={section.staggerOffset}
			title={section.sectionTitle}
		>
			<ListLogos data={images} />
		</SectionGroup>
	);
};

export default PageGallery;
