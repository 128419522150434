/**
 * External dependencies.
 */
import React from 'react';
import SectionGroup from '@components/section-group/section-group';

const PageColumns = ({ section }) => (
	<SectionGroup
		staggerOffset={section.staggerOffset}
		title={section.sectionName}
	>
		<div
			className="entry is-size-md"
			dangerouslySetInnerHTML={{ __html: section.content }}
		/>
	</SectionGroup>
);

export default PageColumns;
