/**
 * External dependencies.
 */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'ramda';

/**
 * Internal dependencies.
 */
import usePageSections from '@hooks/use-page-sections';
import CaseStudyNextPost from '@case-study/blocks/case-study-next-post/case-study-next-post';

const PageSections = ({ nextPost, sections }) => {
	const components = usePageSections();

	if (isNil(sections) || isEmpty(sections)) {
		return null;
	}

	return sections.map((section, idx) => {
		const Component = components[section.fieldGroupName];
		const staggerOffset = (idx + 1) * 100;
		const isSectionCaseStudies = section.fieldGroupName === 'caseStudy_Sectionsoptions_Sections_CaseStudies';

		if (!Component) {
			return null;
		}

		if (isSectionCaseStudies && nextPost) {
			return (
				<Fragment key={section.fieldGroupName + idx}>
					<CaseStudyNextPost
						staggerOffset={staggerOffset}
						nextPost={nextPost}
					/>

					<Component
						section={{
							...section,
							staggerOffset,
						}}
					/>
				</Fragment>
			);
		}

		if (!isSectionCaseStudies && !!nextPost) {
			if (sections.length - 1 === idx) {
				return (
					<Fragment key={section.fieldGroupName + idx}>
						<Component
							section={{
								...section,
								staggerOffset,
							}}
						/>

						<CaseStudyNextPost
							staggerOffset={staggerOffset}
							nextPost={nextPost}
						/>
					</Fragment>
				);
			}
		}

		return (
			<Component
				key={section.fieldGroupName + idx}
				section={{
					...section,
					staggerOffset,
				}}
			/>
		);
	});
};

PageSections.propTypes = {
	sections: PropTypes.oneOfType([
		PropTypes.oneOf([null]),
		PropTypes.array.isRequired,
	]),
};

export default PageSections;
