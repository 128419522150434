/**
 * External dependencies.
 */
import React from 'react';
import { Link } from 'gatsby';

/**
 * Internal dependencies.
 */
import addKeyProperty from '@utils/add-key-property';

const ListJobs = ({ data }) => (
	<ul className="list-simple is-three-columns is-with-border-bottom is-alt">
		{addKeyProperty(data).map((job) => (
			<li key={job._key}>
				<Link to={job.uri}>
					<span>{job.title}</span>

					{job?.jobOptions?.isRemote && (
						<span className="is-faded">Remote</span>
					)}
				</Link>
			</li>
		))}
	</ul>
);

export default ListJobs;
