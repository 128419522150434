/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import Members from '@blocks/members/members';
import SectionGroup from '@components/section-group/section-group';

const PageTeam = ({ section }) => {
	const members = section.membersPosts.map((member) => ({
		id: member.id,
		uri: member.uri,
		image: member.featuredImage.node.mediaItemUrl,
		name: member.title,
		position: member.memberOptions.position,
		content: member.content,
	}));

	return (
		<SectionGroup
			isFull
			staggerOffset={section.staggerOffset}
		>
			<Members members={members} />
		</SectionGroup>
	);
};

export default PageTeam;
