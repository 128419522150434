/**
 * External dependencies.
 */
import React, { Fragment, useState } from 'react';
import SwiperCore, { Navigation, EffectFade, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

/**
 * Internal dependencies.
 */
import SectionGroup from '@components/section-group/section-group';

SwiperCore.use([Navigation, A11y, EffectFade]);

const PageImages = ({ section }) => {
	const [currentSlideIdx, setCurrentSlideIdx] = useState(1);

	return (
		<Fragment>
			<SectionGroup
				isFull
				noSpacing
				staggerOffset={section.staggerOffset}
			>
				<Swiper
					effect="fade"
					spaceBetween={20}
					slidesPerView={1}
					navigation
					onSlideChange={(swiper) => {
						if (swiper) {
							setCurrentSlideIdx(swiper.activeIndex + 1);
						}
					}}
				>
					{section.images.map((image) => (
						<SwiperSlide key={image.id}>
							<div className="entry-image">
								<img
									src={image.mediaItemUrl}
									loading="lazy"
									alt=""
								/>
							</div>
						</SwiperSlide>
					))}
				</Swiper>
			</SectionGroup>

			<SectionGroup
				className="is-slider-pagination"
				title="Images"
				staggerOffset={section.staggerOffset}
			>
				<h6 className="entry-fraction">
					{currentSlideIdx.toString().padStart(2, '0')}/{section.images.length.toString().padStart(2, '0')}
				</h6>
			</SectionGroup>
		</Fragment>
	);
};

export default PageImages;
