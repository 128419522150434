/**
 * External dependencies.
 */
import React from 'react';
import { Link } from 'gatsby';

/**
 * Internal dependencies.
 */
import SectionGroup from '@components/section-group/section-group';

const CaseStudyNextPost = ({ nextPost, staggerOffset }) => (
	<SectionGroup staggerOffset={staggerOffset}>
		<div className="entry-next-post">
			<span className="entry-next-post__title">Next case study</span>

			<Link
				to={nextPost.uri}
				className="entry-next-post__link"
			>
				{nextPost.title}
			</Link>
		</div>
	</SectionGroup>
);

export default CaseStudyNextPost;
