/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { pluck } from 'ramda';

/**
 * Internal dependencies.
 */
import ListSimple from '@components/list-simple/list-simple';
import SectionGroup from '@components/section-group/section-group';

const PageFourColumns = ({ section }) => {
	const list = pluck('text', section.list);

	return (
		<SectionGroup
			staggerOffset={section.staggerOffset}
			title={section.sectionTitle}
		>
			<ListSimple
				isThreeColumns
				data={list}
			/>
		</SectionGroup>
	);
};

PageFourColumns.propTypes = {
	section: PropTypes.object.isRequired,
};

export default PageFourColumns;
