/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

/**
 * Internal dependencies.
 */
import cfs from '@utils/classes-from-string';

const Text = ({ as: HTMLElement, modifier, children }) => {
	const modifiers = cfs('', modifier);

	return (
		<HTMLElement className={cx('entry', modifiers)}>{children}</HTMLElement>
	);
};

Text.propTypes = {
	as: PropTypes.string,
	modifier: PropTypes.string,
	children: PropTypes.node,
};

Text.defaultProps = {
	as: 'p',
};

export default Text;
