/**
 * External dependencies.
 */
import React from 'react';

const Testimonial = ({ title, position, content }) => (
	<div className="testimonial">
		<blockquote
			className="testimonial__blockquote"
			dangerouslySetInnerHTML={{ __html: content }}
		/>

		<div className="testimonial__body">
			<aside className="testimonial__aside" />

			<div className="testimonial__content">
				<h5 className="testimonial__title">{title}</h5>

				<h5 className="testimonial__title is-faded">{position}</h5>

				<div className="testimonial__decorations">
					<span className="testimonial__decoration" />
					<span className="testimonial__decoration" />
					<span className="testimonial__decoration" />
				</div>
			</div>
		</div>
	</div>
);

export default Testimonial;
