/**
 * External dependencies.
 */
import React, { Fragment, useContext } from 'react';

/**
 * Internal dependencies.
 */
import { AccordionSectionContext } from '@components/accordion/accordion-section';
import { AccordionContext } from '@components/accordion/accordion';

const AccordionHead = ({ children }) => {
	const { id } = useContext(AccordionSectionContext);
	const { setActiveSection } = useContext(AccordionContext);

	const handleActiveSection = () => {
		setActiveSection(id);
	};

	return (
		<Fragment>
			<button
				type="button"
				className="accordion__btn"
				onClick={handleActiveSection}
			>
				+
			</button>

			<div className="accordion__head">
				<button
					type="button"
					className="accordion__head-btn"
					onClick={handleActiveSection}
				>
					{children}
				</button>
			</div>
		</Fragment>
	);
};

export default AccordionHead;
