/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import Heading from '@components/heading/heading';
import SectionGroup from '@components/section-group/section-group';

const PageHeading = ({ title, content, textSizeSmall }) => (
	<SectionGroup>
		<Heading
			title={title}
			entry={content}
			textSizeSmall={textSizeSmall}
		/>
	</SectionGroup>
);

PageHeading.propTypes = {
	title: PropTypes.string,
	content: PropTypes.string,
	textSizeSmall: PropTypes.bool,
};

export default PageHeading;
