/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import Member from '@components/member/member';

const Members = ({ members }) => (
	<div className="members">
		{members.map((member) => (
			<Member
				key={member.id}
				member={member}
			/>
		))}
	</div>
);

export default Members;
