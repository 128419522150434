/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import cx from 'classnames'

/**
 * Internal dependencies.
 */
import Contacts from '@components/contacts/contacts';
import Text from '@components/text/text';
import SectionGroup from '@components/section-group/section-group';

const PageContact = ({ contact, staggerOffset, location }) => (
	<SectionGroup
		staggerOffset={staggerOffset}
		title={contact?.contactSettings?.options?.sectionTitle}
		className={cx({'contact-home': location.pathname === '/'})}
	>
		{contact?.contactSettings?.options?.callout && location.pathname === '/' && (
			<Text modifier="is-size-xs">{contact.contactSettings.options.callout}</Text>
		)}

		<Contacts>
			{contact?.contactSettings?.options?.email && (
				<Contacts.Item
					title="Email"
					email={contact.contactSettings.options.email}
				/>
			)}

			{contact?.contactSettings?.options?.phone && (
				<Contacts.Item
					title="Phone"
					phone={contact.contactSettings.options.phone}
				/>
			)}
		</Contacts>
	</SectionGroup>
);

PageContact.propTypes = {
	contact: PropTypes.object.isRequired,
	staggerOffset: PropTypes.number,
};

PageContact.defaultProps = {
	staggerOffset: 0,
	contact: {
		contactSettings: {
			options: {
				callout: '',
				email: '',
				phone: '',
				sectionTitle: 'Contact',
			},
		},
	},
};

export const query = graphql`
    fragment PageContactFragment on Wp {
        contactSettings {
            options {
                callout
                email
                phone
                sectionTitle
            }
        }
    }
`;

export default PageContact;
