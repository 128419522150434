/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import ListLogos from '@components/list-logos/list-logos';
import SectionGroup from '@components/section-group/section-group';
import { isNil } from 'ramda';

const PageCaseStudiesLogos = ({ section }) => {
	const caseStudiesLogos = section.caseStudyPosts
		.filter((caseStudy) => !isNil(caseStudy.featuredImage.node))
		.map((caseStudy) => ({ url: caseStudy.featuredImage.node.mediaItemUrl }));

	return (
		<SectionGroup
			staggerOffset={section.staggerOffset}
			title={section.sectionTitle}
		>
			<ListLogos data={caseStudiesLogos} />
		</SectionGroup>
	);
};

export default PageCaseStudiesLogos;
